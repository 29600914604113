<template>
  <div class="dashboard-org">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1 class="text-h6 mr-3">
            <template v-if="editQuizTitle === true">
              <v-text-field
                label="Quiz Title"
                v-model="quiz.name"
              ></v-text-field>
            </template>
            <template v-else>
              {{ quiz.name }}
              <v-icon class="ml-3" @click="editQuizTitle = true"
                >mdi-playlist-edit</v-icon
              >
            </template>
          </h1>
          <div class="mt-5">
            <strong>Category: </strong>{{ quiz.category.name }}<br />
          </div>
          <div class="mt-5">
            <strong>Date Updated: </strong>{{ quiz.updated_at }}<br />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <!-- <v-select
            v-model="quiz.risk_profiles"
            :items="riskProfiles"
            item-text="profile_name"
            item-value="id"
            chips
            label="Risk Profile"
            multiple
            outlined
          ></v-select> -->
          <v-select
            v-model="quiz.report_template_id"
            :items="reportTemplates"
            label="Report Tempate"
            item-text="name"
            item-value="id"
            outlined
          ></v-select>
          <v-btn color="primary" rounded @click="saveQuiz">Save</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider class="mt-8 mb-3"></v-divider>
          <v-container class="fluid d-lg-flex align-center">
            <v-btn
              class="back-to-blue"
              color="cora-chip-color-blue"
              dark
              rounded
              @click="$router.back()"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" class="ml-auto" rounded>
              Publish Quiz
            </v-btn> -->
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Edit Quiz</v-toolbar>
            <v-card-title class="px-0">
              <v-container class="fluid d-lg-flex align-center">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="ml-auto mr-3"
                  dark
                  rounded
                  @click="addDialog = true"
                >
                  + Add Question
                </v-btn>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-data-table
                v-model="items"
                @sorted="saveOrder"
                v-sortable-data-table
                :loading="dataLoading"
                loading-text="Loading... Please wait"
                item-key="id"
                :disable-sort="true"
                :headers="headers"
                :items="items"
                :sort-desc="[false, true]"
                multi-sort
                class="elevation-5 mb-15 align-center"
                :search="search"
                :custom-filter="customDataTableItemsFilter"
                :footer-props="{
                  itemsPerPage: [5]
                }"
              >
                <template v-slot:item.actions="{ item }">
                  <span class="group">
                    <!-- <v-icon v-if="item.published" :class="enabled_check">
                      mdi-check-circle
                    </v-icon>
                    <v-tooltip v-else bottom>
                      <template #activator="{ on }">
                        <v-icon
                          :class="
                            item.published ? 'enabled_check' : 'disabled_check'
                          "
                          v-on="on"
                          @click="publishItem(item.id)"
                        >
                          mdi-check-circle
                        </v-icon>
                      </template>
                      <span>Publish</span>
                    </v-tooltip> -->
                    <v-icon class="pa-2" @click="editItem(item)"
                      >mdi-playlist-edit</v-icon
                    >
                    <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                  </span>
                </template>
                <template v-slot:item.drag_icon="{ item }">
                  <v-icon v-model="item.id">mdi-drag-vertical</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-6">
          <v-card>
            <v-toolbar color="primary" dark
              >Manage Template Custom Blocks</v-toolbar
            >
            <v-card-title class="px-0">
              <v-container class="fluid d-lg-flex align-center">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="ml-auto mr-3"
                  dark
                  rounded
                  @click="addCBlock"
                >
                  + Add Custom Block
                </v-btn>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :loading="dataLoadingCBlock"
                loading-text="Loading... Please wait"
                item-key="id"
                :disable-sort="true"
                :headers="headersCBlock"
                :items="cBlocks"
                :search="search"
                :sort-desc="[false, true]"
                multi-sort
                show-select
                class="elevation-5 mb-15 align-center"
                :footer-props="{
                  itemsPerPageOptions: [10]
                }"
              >
                <template v-slot:item.actions="{ item }">
                  <span class="group">
                    <v-icon class="pa-2" @click="editCBlock(item)"
                      >mdi-playlist-edit</v-icon
                    >
                    <v-icon @click="deleteCBlock(item)">mdi-delete</v-icon>
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-dialog v-model="dialogCBlock" width="1000">
            <v-card>
              <v-card-title>{{ formTitleCBlock }}</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-form ref="formCBlock" v-model="valid" lazy-validation>
                        <v-text-field
                          filled
                          v-model="editedCBlock.name"
                          label="Name"
                          :rules="[v => !!v || 'Name is required']"
                          required
                        ></v-text-field>
                        <v-text-field
                          filled
                          v-model="editedCBlock.content"
                          label="Content"
                          :rules="[v => !!v || 'Content is required']"
                        ></v-text-field>
                        <v-text-field
                          filled
                          v-model="editedCBlock.internal_notes"
                          label="Internal Notes"
                        ></v-text-field>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="back-to-blue mb-3 ml-5"
                  rounded
                  @click="dialogCBlock = false"
                >
                  cancel
                </v-btn>
                <v-btn
                  class="mb-3 mr-3 ms-auto"
                  color="secondary"
                  rounded
                  @click="saveCBlock(editedCBlock)"
                >
                  save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider class="mt-8 mb-3"></v-divider>
          <v-container class="fluid d-lg-flex align-center">
            <v-btn
              class="back-to-blue"
              color="cora-chip-color-blue"
              dark
              rounded
              @click="$router.back()"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" class="ml-auto" rounded>
              Publish Quiz
            </v-btn> -->
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
    <v-dialog v-model="addDialog" width="500">
      <v-card>
        <v-card-title>Add Question</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" class="full-width-buttons">
                <v-btn
                  @click="showNestedDialog('master')"
                  color="secondary"
                  class="mb-3"
                  rounded
                >
                  + add from master question bank
                </v-btn>
                <v-btn
                  @click="showNestedDialog('mybank')"
                  color="secondary"
                  class="mb-3"
                  rounded
                >
                  + add from my question bank
                </v-btn>
                <v-btn
                  @click="showNestedDialog('custom')"
                  color="secondary"
                  rounded
                  >+ add custom question</v-btn
                >
              </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-dialog
                v-model="masterQuestionsModal"
                persistent
                max-width="1000px"
              >
                <v-card>
                  <v-card-title>{{ bankFormTitle }}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="keywords"
                            type="text"
                            label="Search"
                            append-icon="mdi-magnify"
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-data-table
                            v-model="selectedMasterItems"
                            :loading="masterDataLoading"
                            loading-text="Loading... Please wait"
                            item-key="id"
                            :headers="questionsHeaders"
                            :items="masterItems"
                            :options.sync="masterOptions"
                            :server-items-length="masterItemsTotal"
                            show-select
                            :footer-props="{
                              itemsPerPage: [10]
                            }"
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="pl-5 pr-5 pb-5">
                    <v-btn
                      class="back-to-blue"
                      rounded
                      @click="masterQuestionsModal = false"
                    >
                      cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      class="ms-auto"
                      @click="selectMasterQuestions()"
                    >
                      SELECT QUESTIONS
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="back-to-blue mb-3" rounded @click="addDialog = false">
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="customForm" v-model="valid_custom" lazy-validation>
      <v-dialog v-model="addCustomDialog" persistent max-width="1000px">
        <v-card>
          <v-card-title>{{ formTitle }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    filled
                    v-model="editedItem.content"
                    label="Question"
                    :rules="[v => !!v || 'Content is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Sort Order"
                    v-model="editedItem.sort_order"
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Info Box"
                    v-model="editedItem.help_text"
                  ></v-text-field>
                  <v-subheader>Explanation:</v-subheader>
                  <TiptapVuetify
                    v-model="editedItem.help_html"
                    :extensions="extensions"
                  >
                  </TiptapVuetify>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-subheader>Response Type:</v-subheader>
                  <v-radio-group v-model="editedItem.type" row>
                    <v-radio label="Multiple Choice" value="checkbox"></v-radio>
                    <v-radio label="Single Choice" value="radio"></v-radio>
                    <v-radio label="Text" value="text"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-simple-table
                    v-if="editedItem.type === 'checkbox' || editedItem.type === 'radio'"
                    width="100%"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th class="text-left" width="90%">
                            Text
                          </th>
                          <th width="5%">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in editedResponses"
                          :key="index"
                        >
                          <td vertical-align="middle">
                            {{ index + 1 }}
                          </td>
                          <td>
                            <v-container>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    :label="'Response ' + (index + 1)"
                                    :rules="rules.response"
                                    hide-details="auto"
                                    v-model="item.content"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    label="Explanation"
                                    hide-details="auto"
                                    v-model="item.explanation"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    :rules="rules.risk_score"
                                    label="Score"
                                    hide-details="auto"
                                    v-model="item.risk_score"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-checkbox
                                    v-model="item.hasCondition"
                                    :key="index"
                                    label="Add a conditional question to response"
                                    @click="setHasCondQuestions(item)"
                                  ></v-checkbox>
                                  <p>
                                    If the above response is choosen, the
                                    following child question will be prompted
                                    after the parent question is completed.
                                    Child questions must be added to the
                                    question banks to apppear in the drop down
                                    below.
                                  </p>
                                  <div
                                    class="my-3"
                                    v-if="
                                      item.condition &&
                                        item.condition
                                          .condition_question_content
                                    "
                                  >
                                    <strong>Selected:</strong>
                                    <span
                                      v-html="
                                        item.condition &&
                                          item.condition
                                            .condition_question_content
                                      "
                                    ></span>
                                  </div>

                                  <v-expansion-panels
                                    v-model="expandPanel[index]"
                                    multiple
                                    :disabled="item.hasCondition ? false : true"
                                  >
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>
                                        Select Conditional Question
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-container>
                                          <v-row>
                                            <v-col>
                                              <v-text-field
                                                v-model="condQuestionSearch"
                                                type="text"
                                                label="Search"
                                                append-icon="mdi-magnify"
                                                clearable
                                              ></v-text-field>
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col>
                                              <v-data-table
                                                v-model="selectedCondQuestions"
                                                :loading="condDataLoading"
                                                loading-text="Loading... Please wait"
                                                item-key="id"
                                                :headers="condQuestionsHeaders"
                                                :items="condItems"
                                                :options.sync="condOptions"
                                                :server-items-length="
                                                  condItemsTotal
                                                "
                                                class="elevation-5 mb-15 align-center"
                                                :single-select="true"
                                                show-select
                                                :footer-props="{
                                                  itemsPerPage: [10]
                                                }"
                                              >
                                              </v-data-table>
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col>
                                              <v-btn
                                                color="primary"
                                                class="ms-auto"
                                                @click="
                                                  selectCondQuestion(
                                                    item,
                                                    index
                                                  )
                                                "
                                              >
                                                SELECT CONDITIONAL QUESTION
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                  <p v-if="item.conditionalQuestion">
                                    {{ item.conditionalQuestion.text }}
                                  </p>
                                </v-col>
                              </v-row>
                            </v-container>
                          </td>
                          <td>
                            <v-btn
                              color="secondary"
                              class="my-1"
                              @click="addRow(index)"
                            >
                              Add Response </v-btn
                            ><br />
                            <v-btn
                              color="error"
                              class="mb-5"
                              @click="removeRow(index)"
                            >
                              Remove Response
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="back-to-blue mb-3 ml-5"
              rounded
              @click="cancelEditForm"
            >
              cancel
            </v-btn>
            <v-btn
              :disabled="editedItem.type ? false : true"
              class="mb-3 mr-3 ms-auto"
              color="secondary"
              rounded
              @click="saveItem(editedItem)"
            >
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import CoursesService from "@/services/courses.service";
import moment from "moment";
import Sortable from "sortablejs";
import { Role } from "@/helpers/roles";
//import Tiptap from "@/components/TipTap";
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  name: "Generic",
  components: {
    Header,
    TiptapVuetify
  },
  data() {
    return {
      bankFormTitle: "",
      quizTitle: "Quiz Title",
      editQuizTitle: false,
      addDialog: false,
      masterQuestionsModal: false,
      addCustomDialog: false,
      breadcrumbsItems: [],
      responseType: "checkbox",
      selectedCategory: "",
      myname: "",
      originalItem: {
        content: "",
        help_html: "",
        help_text: "",
        sort_order: "",
        type: "",
        category: "",
        category_id: null
      },
      defaultItem: {
        content: "",
        help_html: "",
        help_text: "",
        sort_order: "",
        type: "",
        category: "",
        category_id: null
      },
      editedItem: {
        content: "",
        help_html: "",
        help_text: "",
        sort_order: "",
        type: "text",
        category: "",
        category_id: null
      },
      originalResponses: [
        {
          id: "",
          question_id: "",
          content: "",
          risk_score: "",
          explanation: null,
          condition: null,
          hasCondition: false
          // hasControlQuestion: false,
          // condQuestion: {
          //   condition_question_content: ""
          // }
        }
      ],
      defaultResponses: [
        {
          id: "",
          question_id: "",
          content: "",
          risk_score: "",
          explanation: null,
          condition: null,
          hasCondition: false
          // condQuestion: {
          //   condition_question_content: ""
          // }
        }
      ],
      editedResponses: [
        {
          id: "",
          question_id: "",
          content: "",
          risk_score: "",
          explanation: null,
          condition: null,
          hasCondition: false
          // hasControlQuestion: false,
          // condQuestion: {
          //   condition_question_content: ""
          // }
        }
      ],
      emptyResponse: {
        id: "",
        question_id: "",
        content: "",
        risk_score: "",
        explanation: null,
        condition: null,
        hasCondition: false
        // hasControlQuestion: false,
        // condQuestion: {
        //   condition_question_content: ""
        // }
      },
      rules: {
        response: [
          value => !!value || "Required.",
          value => (value && value.length > 0) || "Min 1 character"
        ],
        risk_score: [value => !!value || "Required."]
      },
      condCatId: null,
      items: [],
      condItems: [],
      masterItems: [],
      selectedCondQuestions: [],
      selectedMasterItems: [],
      filteredQuestions: [],
      questionsSelected: [],
      showReviewStatusSnach: false,
      reviewStatusSelected: null,
      valueRules: [
        value => !!value || "Required.",
        value => (value && value.length >= 3) || "Min 3 characters"
      ],
      isLoading: false,
      dialog: false,
      snackbar: false,
      valid: true,
      valid_custom: true,
      alert: "",
      dataLoading: true,
      dataLoadingCBlock: false,
      dialogCBlock: false,
      condDataLoading: true,
      masterDataLoading: true,

      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,

      condOptions: {},
      condItemsTotal: 0,
      condCurrentPage: 1,
      condLastPage: null,

      masterOptions: {},
      masterItemsTotal: 0,
      masterCurrentPage: 1,
      masterLastPage: null,

      search: "",
      masterKeywords: "",
      expanded: [],
      singleExpand: true,
      quiz: {},
      headers: [
        { text: "", value: "drag_icon" },
        { text: "ID#", value: "id" },
        { text: "Content", value: "content" },
        { text: "Type", value: "type" },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],

      questionsHeaders: [
        { text: "Content", value: "content", width: "90%" },
        { text: "Type", value: "type", width: "10%" }
      ],
      condQuestionsHeaders: [
        { text: "Content", value: "content", width: "60%" },
        { text: "Type", value: "type" }
      ],
      headersCBlock: [
        { text: "Name", value: "name" },
        { text: "Content", value: "content" },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      cblocks: [],
      expandPanel: [],
      questionsModal: false,
      editedIndex: -1,
      approvedReports: 0,
      rejectedReports: 0,
      pendingReports: 0,
      //riskProfiles: [],
      //quiz_risk_profiles: [],
      reportTemplates: [],
      condQuestionSearch: null,
      keywords: "",
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],

      editedCBlock: {
        quiz_id: "",
        name: "",
        content: "",
        internal_notes: ""
      },
      originalCBlock: {
        quiz_id: "",
        name: "",
        content: "",
        internal_notes: ""
      },
      defaultCBlock: {
        quiz_id: "",
        name: "",
        content: "",
        internal_notes: ""
      },
      cBlocks: [],
      formTitleCBlock: "",
      editedIndexCBlock: -1
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    keywords: {
      async handler() {
        if (this.keywords) this.searchItems();
        else this.getMasterItems();
      },
      deep: true
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    custom() {
      return this.isAdminEditor ? 0 : 1;
    },

    formTitle() {
      return this.editedIndex === -1 ? "Create New Question" : "Edit Question";
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },

    catId() {
      return this.quiz.category_id;
    },

    courseId() {
      return this.quiz.custom_course_id;
    },

    quizId() {
      return this.$route.params.id;
    },

    orgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.initialize();
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function(event) {
            vnode.child.$emit("sorted", event);
          }
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      }
    }
  },

  methods: {
    cancelEditForm() {
      this.addCustomDialog = false;
      this.editedIndex = -1;
    },

    showNestedDialog(type) {
      if (type === "custom") {
        this.addCustomDialog = true;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedResponses = this.defaultResponses;
        this.$refs.customForm.reset();
        //this.getPagedItems();
      } else if (type === "master") {
        this.bankFormTitle = "Add From Master Bank";
        this.masterQuestionsModal = true;
        this.getMasterItems(type);
      } else if (type === "mybank") {
        this.bankFormTitle = "Add From My Bank";
        this.masterQuestionsModal = true;
        this.getMasterItems(type);
      }
      this.addDialog = false;
    },
    // async setRiskProfiles() {
    //   const response = await CoursesService.getRiskProfiles();
    //   this.riskProfiles = response.data.data.slice();
    // },

    async setReportTemplates() {
      const response = await CoursesService.getReportTemplates();
      this.reportTemplates = response.data.data.slice();
    },

    async getPagedItems() {
      this.dataLoading = true;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.options;

      const response = await CoursesService.getQuestionsByCategory({
        id: this.catId,
        query: {
          page: page,
          custom: this.custom
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.items = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.items = this.items.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }
      this.dataLoading = false;
    },

    async getAllQuestions() {
      this.condDataLoading = true;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.condOptions;

      const response = await CoursesService.getQuestionsByCategory({
        id: this.catId,
        query: {
          page: page
        }
      });

      this.condOptions.itemsPerPage = parseInt(response.data.meta.per_page);
      this.condItemsTotal = response.data.meta.total;
      this.condLastPage = response.data.meta.last_page;

      this.condItems = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.condItems = this.condItems.slice(
          (this.condCurrentPage - 1) * itemsPerPage,
          this.condCurrentPage * itemsPerPage
        );
      }
      this.condDataLoading = false;
    },

    async getMasterItems(type) {
      this.masterDataLoading = true;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.masterOptions;

      const response = await CoursesService.getQuestionsByCategory({
        id: this.catId,
        query: {
          page: page,
          custom: type === "master" ? 0 : 1
        }
      });

      this.masterOptions.itemsPerPage = parseInt(response.data.meta.per_page);
      this.masterItemsTotal = response.data.meta.total;
      this.masterLastPage = response.data.meta.last_page;

      this.masterItems = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.masterItems = this.masterItems.slice(
          (this.masterCurrentPage - 1) * itemsPerPage,
          this.masterCurrentPage * itemsPerPage
        );
      }
      this.masterDataLoading = false;
    },

    publishItem(id) {
      console.log("item published", id);
    },
    addRow(index) {
      const empty = Object.assign({}, this.emptyResponse);
      try {
        this.editedResponses.splice(index + 1, 0, empty);
      } catch (e) {
        console.log(e);
      }
    },
    removeRow(index) {
      this.editedResponses.splice(index, 1);
    },
    saveOrder(event) {
      const movedItem = this.quizQuestions.splice(event.oldIndex, 1)[0];
      this.quizQuestions.splice(event.newIndex, 0, movedItem);
    },
    handleQuestionsSelect() {
      this.questionsModal = false;
      this.masterQuestionsModal = false;
    },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      //this.getPagedItems();
      //this.setRiskProfiles();
      this.setReportTemplates();
      this.dataLoading = true;
      //this.itemsTotal = this.items.length;

      const quizData = await CoursesService.getQuiz(this.$route.params.id);
      this.quiz = quizData.data.data;
      this.items = this.quiz.questions;
      //this.quiz_risk_profiles = this.quiz.risk_profiles;
      //console.log("risk profiles", this.quiz_risk_profiles);
      this.formatQuizDate();
      this.setBreadcrumbs();
      //this.setRiskProfiles();
      this.setCBlocks();
      this.dataLoading = false;
    },

    setBreadcrumbs() {
      this.breadcrumbsItems = [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Courses",
          disabled: false,
          href: "/mycourses"
        },
        {
          text: this.quiz.name,
          disabled: true,
          href: this.$route.fullPath
        }
      ];
    },

    getWgtypeId() {
      return this.currentUser.user.workergroups
        ? this.currentUser.user.workergroups[0].wgtype_id
        : null;
    },

    editItem(item) {
      this.condCatId = null;
      this.originalItem = Object.assign({}, item);
      // get question
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (item.type === "checkbox" || item.type === "radio") {
        //this.setResponses(this.editedItem.id);
        this.editedResponses = item.responses;
        this.editedResponses.forEach(response => {
          let condQuestion = item.conditions.find(
            val => val.condition_response_id === response.id
          );
          if (condQuestion) {
            response.condQuestion = condQuestion;
            response.hasCondition = true;
          } else {
            response.condQuestion = null;
          }
        });
      } else {
        this.editedResponses = this.defaultResponses;
      }
      this.addCustomDialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveItem(item) {
      const isValid = this.$refs.customForm.validate();
      if (isValid) {
        let data = {};
        let message = "The Question has been updated successfully";
        if (this.editedIndex > -1) {
          // delete item
          if (!this.isAdminEditor) {
            data = {
              custom: true,
              organization_id: this.orgId
            };
          }
          //remove question
          await CoursesService.removeQuestion({
            id: item.id,
            data: data
          });
        } else {
          message = "The Question has been added successfully";
        }
        // 1. create question

        data = {
          label: "label",
          content: item.content,
          help_html: item.help_html,
          help_text: item.help_text,
          type: item.type,
          custom: true,
          organization_id: this.orgId,
          category_id: this.catId,
          sort_order: item.sort_order
        };

        const response = await CoursesService.createQuestion({
          data: data
        });

        // 2. get question id
        const savedItem = response.data.data;
        const question_id = savedItem.id;

        if (item.type === "checkbox" || item.type === "radio") {
          //create copy of responses array
          this.originalResponses = this.editedResponses.slice();

          //create data - array of responses
          const responsesData = [];

          this.editedResponses.forEach(item => {
            responsesData.push({
              question_id: question_id,
              content: item.content,
              explanation: item.explanation,
              risk_score: item.risk_score,
              custom: true,
              organization_id: this.orgId
            });
          });

          // 3. create responses
          const newResponses = await CoursesService.createResponses({
            data: responsesData
          });

          // 4. get response ids
          this.editedResponses = newResponses.data.data;

          // prepare data for question_codition
          const question_conditionData = [];

          this.originalResponses.forEach((item, index) => {

            this.originalResponses[index].question_id = question_id;
            this.originalResponses[index].id = this.editedResponses[index].id;
            if (this.originalResponses[index].condQuestion != null) {
              // this.originalResponses[index].condQuestion.question_id = question_id;
              // this.originalResponses[index].condQuestion.condition_response_id = this.editedResponses[index].id;
              if (this.isAdminEditor) {
                question_conditionData.push({
                  question_id: question_id,
                  condition_question_id: this.originalResponses[index]
                    .condQuestion.condition_question_id,
                  condition_response_id: this.editedResponses[index].id
                });
              } else {
                question_conditionData.push({
                  question_id: question_id,
                  condition_question_id: this.originalResponses[index]
                    .condQuestion.condition_question_id,
                  condition_response_id: this.editedResponses[index].id,
                  custom: true,
                  organization_id: this.orgId
                });
              }
            }
          });

          if (question_conditionData.length > 0) {
            //5. create question_conditions
            await CoursesService.createQuestionConditions({
              data: question_conditionData
            });
          }

          //reset the editedResponses to default
          this.editedResponses = this.defaultResponses.slice();
        }

        // assign qestion to quiz
        await CoursesService.createQuizQuestions({
          data: {
            quiz_id: this.quizId,
            question_ids: [question_id],
            custom: this.custom,
            organization_id: this.orgId
          }
        });

        const responseQuestion = await CoursesService.getQuestion({
          id: savedItem.id
        });

        let savedQuestion = responseQuestion.data.data;

        if (this.editedIndex > -1) {
          this.items.splice(this.editedIndex, 1, savedQuestion);
        } else {
          this.items.push(savedQuestion);
        }

        this.editedIndex = -1;

        this.showSnackbar(message);
        this.addCustomDialog = false;
        this.$refs.form.reset();
      } else {
        console.log("Data not valid");
      }
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this question?") &&
        this.items.splice(index, 1);

      let data = {
        custom: this.custom,
        organization_id: this.orgId
      };

      //remove question
      await CoursesService.removeQuizQuestion({
        id: item.id,
        data: data
      });

      this.showSnackbar(item.content + " has been deleted");
    },

    async selectMasterQuestions() {
      const question_ids = this.selectedMasterItems.map(item => {
        return item.id;
      });

      await CoursesService.createQuizQuestions({
        data: {
          quiz_id: this.quizId,
          question_ids: question_ids,
          custom: this.custom,
          organization_id: this.orgId
        }
      });
      this.masterQuestionsModal = false;
      this.$router.go();
    },

    customDataTableItemsFilter(value, search, items) {
      /*
      Filter for individual words in search string. Filters
      all object values rather than just the keys included
      in the data table headers.
       */
      const wordArray = search
        .toString()
        .toLowerCase()
        .split(" ")
        .filter(x => x);
      return wordArray.every(word =>
        JSON.stringify(Object.values(items))
          .toString()
          .toLowerCase()
          .includes(word)
      );
    },

    selectCondQuestion(response, index) {
      const condQuestion = {
        question_id: "",
        condition_question_id: "",
        condition_response_id: "",
        condition_question_content: ""
      };
      condQuestion.condition_question_id = this.selectedCondQuestions[0].id;
      condQuestion.condition_question_content = this.selectedCondQuestions[0].content;

      response.condQuestion = condQuestion;
      this.selectedCondQuestions = [];
      this.expandPanel[index] = false;
    },
    async saveQuiz() {
      let data = {};
      if (this.isAdminEditor) {
        //Admin user
        data = {
          name: this.quiz.name,
          wgtype_id: this.getWgtypeId(),
          start_button_text: this.quiz.start_button_text,
          started_heading: this.quiz.started_heading,
          started_html: this.quiz.started_html,
          completed_heading: this.quiz.completed_heading,
          completed_html: this.quiz.completed_html,
          custom: this.custom,
          category_id: this.catId,
          type: this.quiz.type,
          report_template_id: this.quiz.report_template_id
        };
      } else {
        //full1 user
        data = {
          name: this.quiz.name,
          wgtype_id: this.getWgtypeId(),
          start_button_text: this.quiz.start_button_text,
          started_heading: this.quiz.started_heading,
          started_html: this.quiz.started_html,
          completed_heading: this.quiz.completed_heading,
          completed_html: this.quiz.completed_html,
          custom: this.custom,
          organization_id: this.orgId,
          category_id: this.catId,
          type: this.quiz.type,
          report_template_id: this.quiz.report_template_id
        };
      }
      //delete existing risk profiles for the quiz
      // if (this.quiz_risk_profiles.length > 0) {
      //   for (const profile of this.quiz_risk_profiles) {
      //     console.log("profile", profile);
      //     await CoursesService.removeQuizRiskProfiles({
      //       data: {
      //         quiz_id: this.quiz.id,
      //         risk_profile_id: profile.id,
      //         custom: this.custom,
      //         organization_id: this.orgId
      //       }
      //     });
      //   }
      // }
      //console.log("risk_profiles",this.quiz.risk_profiles);
      //assign risk profiles to the quiz
      // for (const profile_id of this.quiz.risk_profiles) {
      //   const riskProfileData = {
      //     quiz_id: this.quiz.id,
      //     risk_profile_id: profile_id,
      //     custom: this.custom,
      //     organization_id: this.orgId
      //   };
      //   console.log("riskProfileData",riskProfileData);
      //   await CoursesService.createQuizRiskProfile({ data: riskProfileData });
      //}

      const response = await CoursesService.updateQuiz({
        id: this.quiz.id,
        data: data
      });

      this.editedItem = response.data;

      this.showSnackbar("The Quiz has been updated successfully");
      this.editQuizTitle = false;
    },
    formatQuizDate() {
      this.quiz.updated_at = moment(this.quiz.updated_at).format(
        "YYYY-MM-DD h:mm a"
      );
    },
    setHasCondQuestion(item, editedItem) {
      if (item.hasCondition === false) {
        item.condQuestion = null;
      } else {
        if (this.isAdminEditor) {
          item.condQuestion = {
            question_id: "",
            condition_question_id: "",
            condition_response_id: "",
            condition_question: { content: "" }
          };
        } else {
          item.condQuestion = {
            question_id: "",
            condition_question_id: "",
            condition_response_id: "",
            custom: true,
            organization_id: this.orgId,
            condition_question: { content: "" }
          };
        }
      }
      this.condCatId = editedItem.category_id;
      this.setCondItems();
    },
    async setCondItems() {
      this.condDataLoading = true;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.condOptions;

      const response = await CoursesService.getQuestionsByCategory({
        id: this.condCatId,
        query: {
          page: page
        }
      });

      this.condOptions.itemsPerPage = parseInt(response.data.meta.per_page);
      this.condItemsTotal = response.data.meta.total;
      this.condLastPage = response.data.meta.last_page;

      this.condItems = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.condItems = this.condItems.slice(
          (this.condCurrentPage - 1) * itemsPerPage,
          this.condCurrentPage * itemsPerPage
        );
      }
      this.condDataLoading = false;
    },
    async searchItems() {
      this.masterDataLoading = true;
      const { page, itemsPerPage } = this.masterOptions;
      let data;
      if (this.keywords) {
        data = {
          keywords: this.keywords,
          category_id: this.catId,
          custom: this.custom
        };
      }

      const response = await CoursesService.getQuestionsSearch({
        data: data,
        query: {
          page: page
        }
      });

      this.masterOptions.itemsPerPage = parseInt(response.data.meta.per_page);
      this.masterItemsTotal = response.data.meta.total;
      this.masterLastPage = response.data.meta.last_page;

      this.masterItems = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.items = this.items.slice(
          (this.masterCurrentPage - 1) * itemsPerPage,
          this.masterCurrentPage * itemsPerPage
        );
      }

      this.masterDataLoading = false;
    },

    async setCBlocks() {
      this.dataLoadingCBlock = true;

      const response = await CoursesService.getCBlocks({
        quizId: this.quiz.id
      });

      this.cBlocks = response.data.data.slice();
      this.dataLoadingCBlock = false;
    },

    editCBlock(item) {
      this.formTitleCBlock = "Edit Custom Block";
      this.originalCBlock = Object.assign({}, item);
      this.editedIndexCBlock = this.cBlocks.indexOf(item);
      this.editedCBlock = Object.assign({}, item);
      this.dialogCBlock = true;
    },

    async saveCBlock(item) {
      const isValid = this.$refs.formCBlock.validate();
      let data = {};
      if (isValid) {
        if (this.editedIndexCBlock > -1) {
          data = {
            quiz_id: this.quiz.id,
            name: item.name,
            content: item.content,
            internal_notes: item.internal_notes
          };

          const response = await CoursesService.updateCBlock({
            id: item.id,
            data: data
          });
          this.cBlocks.splice(this.editedIndexCBlock, 1, response.data);
          this.showSnackbar("The Custom Block has been updated successfully");
        } else {
          data = {
            quiz_id: this.quiz.id,
            name: item.name,
            content: item.content,
            internal_notes: item.internal_notes
          };

          const response = await CoursesService.createCBlock({
            data: data
          });

          this.cBlocks.push(response.data);
          this.showSnackbar("The Custom Block has been added successfully");
        }
      }
      this.dialogCBlock = false;
      this.$refs.formCBlock.reset();
    },

    async deleteCBlock(item) {
      const index = this.cBlocks.indexOf(item);
      confirm("Are you sure you want to delete this custom block?") &&
        this.cBlocks.splice(index, 1);

      await CoursesService.removeCBlock({
        id: item.id
      });

      this.showSnackbar(item.name + " has been deleted");
    },

    addCBlock() {
      this.formTitleCBlock = "Add Custom Block";
      this.dialogCBlock = true;
      this.editedIndexCBlock = -1;
      this.$refs.formCBlock.reset();
      this.editedCBlock = Object.assign({}, this.defaultCBlock);
    }
  }
};
</script>
<style lang="scss" scoped>
.lock-icon {
  color: red;
}

.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #c5e1e9 !important;
  color: #2f91ae;
}
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
.resource-card {
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
